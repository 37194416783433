import {UpdateBy} from './updated-by.interface';

export interface ApiResponseLinks {
  first: string;
  last: string;
  prev: string;
  next: string;
  self: string;
}

interface Link {
  active: boolean;
  label: string;
  url: string;
}

export interface ApiResponse<T> {
  data: T;
  meta: {
    current_page?: number;
    from: number;
    last_page: number;
    links: Link[];
    path: string;
    per_page: number;
    to: number;
    total: number;
    updated_at?: string;
    updatedBy?: UpdateBy | null;
  };
  links?: ApiResponseLinks;
}
